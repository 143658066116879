<template>
    <div>
        <CContainer fluid>
            <CRow>
                <CCol sm="12">

                    <main class="main pt-5">
                        <div class="cabezal">
                      
                            <h1>Privacy Policy</h1>
                        </div>
                        <div class="terms_contianer">
                            <div class="contenido_terms">
                                <p>UP LIMO INC privacy policy focuses towards protecting the information that you share with us. All the personal information related to the transactions and activities you perform on UP LIMO INC will solely be used by mydestinylimo.com and will not be shared with any third-party platform for any reason whatsoever. UP LIMO INC updates its privacy policy from time to time in order to ensure data protection of the customers who have subscribed onto our platform. Kindly review our privacy policy periodically to stay updated. No notice will be provided before changing privacy policy. By agreeing to share your information on UP LIMO INC, you accept the practices described in the privacy policy.</p>
                                <h2>Information Collected From You: </h2>
                                <p>We collect information from you in order to provide you with better services. The information collected from you could be your contact number, email ID, address, etc. In case of subscribing to our services, we may ask you to provide us with your credit/debit card number for a hassle-free transaction. Personal information of your friends will also be collected just in case you connect your contact and address book information with our platform. If you choose to share information of your social accounts like Facebook, Twitter, Instagram, etc., then all the public information related to those accounts will be collected. By providing us with such data, you represent and warrant that you are authorized to provide us with such information. We have divided demographic information into two categories:</p>
                                <h2>1. Public Information:</h2>
                                <p>It consists of all the demographic information except for the communication occurred between you and other users on UP LIMO INC platform.</p>
                                <h2>2. Non-public Information:</h2>
                                <p>It consists of ride transaction information along with 1-to-1 communication between you and other users on the UP LIMO INC platform. 
                                    We at UP LIMO INC don’t collect any personal information from children below 18 years of age. So, we want all users who access our website to be at least 18 years old.
                                </p>
                                <h2>3. How Your Personal Information Is Used Or Disclosed To The 3rd Parties:</h2>
                                <p>Your personal information that includes your email address and contact number will be used by us to provide you with better services at UP LIMO INC. The personal information provided by you is going to help you stay in touch with UP LIMO INC platform and getting all the updates regarding new schemes and offers. Your information will help us locate your referrals. These are referred to operational communications and they may contain commercial messages as well. 
                                    We may share your personal information with our service providers, representatives and other staff members so that we are able to receive support services like customer relationship management services, email origination and order fulfilment. Apart from these, your information will not be used anywhere else. 
                                </p>
                                <p>By registering or purchasing to any 3rd party services for any contest or other programs, you are giving us consent to provide your personal information to those 3rd party services. How your personal information is used by them totally depends upon their own privacy policy. To change your preferences, you need to contact those 3rd party services and know how they will use your information. Moreover, you agree that the information you’ve submitted to 3rd parties may be used and disclosed by us in the same manner as the information that you’ve submitted to us. </p>
                                <p>Any 3rd party, we are associated with has the authority to use that information in accordance with the contractual arrangements between that 3rd party company and us, and in accordance with their own privacy policy. Additionally, we can’t be held responsible for any actions or exclusion.</p>
                                <h2>4. How Your Information Is Collected, Used And Distributed:</h2>
                                <p>You as a user of UP LIMO INC can control your personal information that has been collected by you, but you need to follow certain instructions, which are described in section 4. If you are not interested in receiving any promotional emails or messages, or communication from us and don’t want to disclose your personal information to any 3rd party service apart from the delivery of UP LIMO INC services, then you can write to us at info@e-booknow.com 
                                    Important Note: Any information provided by you directly to any 3rd party services that are linked with UP LIMO INC will be liable for different set of rules. As, we have no control as to how 3rd parties will use your personal information. Therefore, we recommend you to ask questions before disclosing your personal information to 3rd parties. 
                                </p>
                                <p>At the time of investigating or resolving an issue raised by you, we are authorized to disclose any information about you to any government officials or private entities, which we believe will fasten the resolution process.</p>
                                <h2>5. Correction, Updating And Deletion Of Your Personal Information: </h2>
                                <p>You can update or delete your personal information by contacting UP LIMO INC at 1-866-590-5444. We will require certain personal information (security check) before we could make the necessary changes as suggested by you. If you have shared your personal information with any 3rd party company before, which has now been removed from their records, then we shall not be held responsible for that. 
                                    Information deleted by you will neither be used by us in the future, nor will it be disclosed to 3rd parties. Information deleted or changed in the registration database may still be present in other databases as archives. By deleting your personal information, you will not be able to receive any service related information from us.
                                </p>
                                <h2>6. Our Security Precautions:</h2>
                                <p>In order to protect your personal information, we have developed a comprehensive security procedures. The information will remain confidential and secure at  UP LIMO INC as we use SSL and firewalls along with various offline methods to keep the intruders out of our sophisticated systems. Even the employees of UP LIMO INC have a limited access to your personal information, and only the information that is related to their field is provided to them. Though, we can’t guarantee an impenetrable security, so 3rd parties may unlawfully barge into the systems to access the information. </p>
                                <p>Our website is maintained in the US, so by using the website, you authorize that your personal information can be exported to the US along with its storage and use.</p>
                                <h2>7. Changes To Our Policy: </h2>
                                <p> UP LIMO INC has the authority to change or amend the current policy at any time without giving any prior notice. Any revisions to the current policy will be posted on the website and users can have a look at those revisions. Any user who adds any further information after the changes in the policy will be considered to have accepted the revised policy. If a situation arises where we decide to use the personal information in any other way from what it was stated at the time of collecting the information, then we will notify the user of all the changes and seek their permission whether he/she allow us to use the information or not. In case, a user denies using the information according to the revised policy, then it will be used according to the privacy policy under which the information was collected.</p>
                                <h2>8. Effective Date:</h2>
                                <p>This privacy policy is effective and was updated on 07/20/16. At UP LIMO INC, we hope this privacy policy resolves our agenda regarding your personal information. In case of any queries, leave us an email at info@e-booknow.com.</p>
                            </div>
                        </div>
                    </main>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        margin-top: 35px;
    }
</style>